import React, { useState, useEffect } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
const ScrollTopBtn = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top hoverable">
          <BiUpArrowAlt />
        </button>
      )}
    </>
  );
};

export default ScrollTopBtn;
