import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const SingleExpertise = ({ img, preTitle, title, desc }) => {
  return (
    <AnimationOnScroll
      animateIn="animate__fadeInUp"
      animatePreScroll={false}
      animateOnce
    >
      <div className="expertise expertiseLeft">
        <div className="expertiseTitle">
          <img src={img} alt="Software Developer" />
          <h1>
            <span className="chonky-underline-megenta">{preTitle} </span>
            <br></br>
            {title}
          </h1>
        </div>
        <div className="expertiseContent">
          <p>{`<h4>`}</p>
          <h3>{desc}</h3>
          <p>{`</h4>`}</p>
        </div>
      </div>
    </AnimationOnScroll>
  );
};

export default SingleExpertise;
