/* eslint-disable no-useless-concat */
import React from "react";
import "../Style/expertise.scss";
import bg from "../Assets/expertiseBg2.svg";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { expertise } from "../data";
import SingleExpertise from "./Expertise/SingleExpertise";

const Expertise = () => {
  return (
    <div className="mainWrapperExpertise">
      <div id="expertise"></div>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        animateOnce
      >
        <h1 className="expertiseTitle">My Expertise</h1>
      </AnimationOnScroll>

      <div className="expertises">
        {expertise.map((exp) => {
          return (
            <SingleExpertise
              img={exp.img}
              desc={exp.desc}
              preTitle={exp.preTitle}
              title={exp.title}
              key={exp.title}
            />
          );
        })}
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <div className="expertiseQuote">
            <h1>Fix the cause, not the symptom.</h1>
            <h2>- Steve Maguire</h2>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="expertiseBgImage">
        <img src={bg} alt="bgImage" />
      </div>
    </div>
  );
};

export default Expertise;
