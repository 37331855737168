import React from "react";
import Experience from "../Components/Experience";
import Expertise from "../Components/Expertise";
import MainBanner from "../Components/MainBanner";
import Work from "../Components/Work";
import { Analytics } from "@vercel/analytics/react";

const Home = () => {
  return (
    <>
      <MainBanner />
      <Expertise />
      <Work />
      <Experience />
      <Analytics />
    </>
  );
};

export default Home;
