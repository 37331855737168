import React from "react";
import "../Style/work.scss";
import mib from "../Assets/mib.svg";
import omnee from "../Assets/omnee.png";
import cooper from "../Assets/cooper.svg";
import formify from "../Assets/formify.svg";
import creabox from "../Assets/creabox.svg";
import dukagjini from "../Assets/dukagjini.svg";
import shqd from "../Assets/shqd.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { MdMinimize } from "react-icons/md";

const Work = () => {
  return (
    <div className="workMainWrapper" id="work">
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        animateOnce
      >
        <h1>My Work</h1>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        animateOnce
      >
        <h2>
          Deployed scalable websites and mobile apps using React and Flutter
        </h2>
      </AnimationOnScroll>
      <div className="workGrid">
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://apps.apple.com/rs/app/p%C3%ABr-shqip%C3%ABrin%C3%AB-q%C3%AB-duam/id1300263940"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${shqd})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>PËR SHQIPËRINË QË DUAM x ikubINFO</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://creaboxagency.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${creabox})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>Creabox Agency x Freelance</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://hoteldukagjini.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${dukagjini})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>Dukagjini Hotel x Black Bird Marketing</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>

        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://www.myirelandbox.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${mib})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>MyIrelandBox x Black Bird Marketing</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>

        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://omneeweb.de/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${omnee})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>OmneeWeb x Black Bird Marketing</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://cooperandkid.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${cooper})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>CooperAndKid x Black Bird Marketing</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animatePreScroll={false}
          animateOnce
        >
          <a
            className="workMiniCard"
            href="https://www.getformify.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="miniCardImageHolder"
              style={{ backgroundImage: `url(${formify})` }}
            ></div>
            <div className="miniCardInfoHolder">
              <h1>Formify x Black Bird Marketing</h1>
              <p>
                <MdMinimize width="100px" /> Click to view more
              </p>
            </div>
          </a>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default Work;
