import React from "react";
import "../Style/mainBanner.scss";
// import bg from "../Assets/orikgashi-home-cover.jpg"
import bg from "../Assets/giphy.jpg";
import animation2 from "../Assets/animation2.json";
import Lottie from "react-lottie-player";
import { AnimationOnScroll } from "react-animation-on-scroll";

const MainBanner = () => {
  return (
    <div className="mainBanner" style={{ backgroundImage: `url(${bg})` }}>
      <div className="circle"></div>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={true}
        initiallyVisible={true}
        animateOnce
      >
        <h1>ORIK GASHI</h1>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        initiallyVisible={true}
        animatePreScroll={true}
        animateOnce
      >
        <h2>SOFTWARE & APP DEVELOPER</h2>
      </AnimationOnScroll>
      <Lottie
        loop
        animationData={animation2}
        play
        style={{ width: 70, height: 70, cursor: "pointer" }}
        className="animation2 hoverable"
        onClick={() => {
          document.getElementById("expertise").scrollIntoView({
            behavior: "smooth",
          });
        }}
      />
    </div>
  );
};

export default MainBanner;
