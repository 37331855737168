/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Style/navbar.scss";
import { Twirl as Hamburger } from "hamburger-react";
import disableScroll from "disable-scroll";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  var year = new Date().getFullYear();
  isOpen ? disableScroll.on() : disableScroll.off();
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150
        ? setStickyClass("sticky-nav animate__animated animate__fadeInDown")
        : setStickyClass("");
    }
  };

  return (
    <div className={`navbarWrapper ${stickyClass}`}>
      <div className="burgerIcon">
        <Hamburger toggled={isOpen} toggle={setOpen} size={25} />
      </div>

      <Link to="/" className="navbarMainLink hoverable">
        {/* OrikGashi<span>;</span>
          <span>_</span> */}
        {`<OrikGashi/>`}
      </Link>
      <div className="navbarLinks">
        <p
          onClick={() => {
            document.getElementById("expertise").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          // expertise
        </p>
        <p
          onClick={() => {
            document.getElementById("work").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          // work
        </p>
        <p
          onClick={() => {
            document.getElementById("experience").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          // experience
        </p>
        <p
          onClick={() => {
            document.getElementById("contact").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          // contact
        </p>
      </div>
      <div
        className={
          isOpen
            ? "navbarMobileOverlay navbarMobileOpen "
            : "navbarMobileOverlay"
        }
        style={{
          display: isOpen ? "flex" : "none",
        }}
      >
        <div className={"navbarMobile animate__animated animate__fadeInLeft"}>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            size={25}
            color="white"
          />
          <div className="navbarMobileLinks">
            <p
              onClick={() => {
                setOpen(false);
                document.getElementById("expertise").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              // expertise{" "}
            </p>
            <p
              onClick={() => {
                setOpen(false);

                document.getElementById("work").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              // work
            </p>
            <p
              onClick={() => {
                setOpen(false);

                document.getElementById("experience").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              // experience
            </p>
            <p
              onClick={() => {
                setOpen(false);

                document.getElementById("contact").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              // contact
            </p>
            <h3>© {year} All rights reserved.</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
