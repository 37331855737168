import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import ScrollToTop from "./Components/ScrollToTop";
import Footer from "./Components/Footer";
import ScrollTopBtn from "./Components/ScrollTopBtn";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
          </Routes>
          <Footer />
          <ScrollTopBtn />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
