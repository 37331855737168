import React from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";

const Dropdown = ({
  showDropdown,
  setShowDropdown,
  title,
  startYear,
  endYear,
  location,
  website,
  expDesc,
  stacks,
  logo,
  webDomain,
}) => {
  return (
    <div className="dropdown-wrapper animate__animated animate__fadeIn">
      <button
        onClick={setShowDropdown}
        className={
          showDropdown
            ? "trigger-button activeBtn hoverable"
            : "hoverable trigger-button"
        }
      >
        {title}
        <div className="startEnd">
          <span>
            {startYear} - {endYear}
          </span>{" "}
          {showDropdown ? (
            <AiOutlineMinus size={20} />
          ) : (
            <AiOutlinePlus size={20} />
          )}
        </div>
      </button>
      <div
        className={
          showDropdown
            ? "expHolder active animate__animated animate__fadeIn"
            : "expHolder"
        }
      >
        <div className="expInfos">
          <div className="location">
            <MdLocationPin color="rgba(1, 108, 128, 1)" size={20} /> {location}{" "}
            <a href={website} target="_blank" rel="noreferrer">
              <GoLinkExternal size={20} color="rgba(1, 108, 128, 1)" />{" "}
              {webDomain}
            </a>
          </div>
          <ul
            className="expDesc"
            dangerouslySetInnerHTML={{ __html: expDesc }}
          ></ul>
          {stacks ? (
            <div className="stacks">
              {stacks.map((e, i) => (
                <div
                  key={i}
                  className="stack"
                  style={i !== 0 ? { marginLeft: "10px" } : { marginLeft: "0" }}
                >
                  {e}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {logo !== "" ? (
          <img src={logo} alt="logo" className="expLogo" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
