import React, { useState } from "react";
import "../Style/experience.scss";
import Dropdown from "./Dropdown";
import ikubLogo from "../Assets/ikublogo.png";
import blackbird from "../Assets/bbm.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Experience = () => {
  const [showDropdown, setShowDropdown] = useState(true);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  var stacksIkub = [
    "React",
    "React Native",
    "Angular",
    "Flutter",
    "Javascript",
    "Dart",
    "NodeJs",
    "ExpressJs",
    "MongoDb",
    "Typescript",
  ];
  var stacksDemogency = ["React", "React Native", "Javascript", "Typescript"];
  var stacksBbm = ["React", "Flutter", "Javascript", "Dart"];
  var stacksRender = [
    "React",
    "React Native",
    "Javascript",
    "Typescript",
    "NextJs",
    "NodeJs",
    "ExpressJs",
    "MongoDb",
  ];

  return (
    <div className="experienceMainWrapper" id="experience">
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        animateOnce
      >
        <h1>
          Professional<br></br>Experience
        </h1>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        className="expAnimation"
        animateOnce
      >
        <Dropdown
          showDropdown={showDropdown}
          title="Software Developer @ ikubINFO"
          startYear="Sep 2022"
          endYear="present"
          setShowDropdown={() => {
            setShowDropdown(!showDropdown);
            setShowDropdown1(false);
            setShowDropdown2(false);
            setShowDropdown3(false);
          }}
          location="Tirana, Albania"
          website="https://ikubinfo.al/"
          stacks={stacksIkub}
          logo={ikubLogo}
          expDesc="
          <strong>Lead Mobile Developer</strong>
          <li>Developed mobile applications using Flutter, Dart, and GetX for various government-related projects.</li>
  <li>Enabled digital management of processes, including inspections and reporting, with features like document generation with electronic signatures and geolocation tracking.</li>
  <li>Implemented SQLite for offline functionality, ensuring data synchronization when back online.</li>
  <li>Integrated multimedia support for attaching photos and videos, enhancing the reporting and inspection processes.</li>
  <li>Designed secure user authentication systems, including integration with national systems and device-based authentication for user anonymity.</li>
  <strong>Front-End Developer</strong>

   <li>Built and maintained full-stack web applications using ReactJS for the front end, and Node.js, Express.js, and MongoDB for the back end.</li>
  <li>Developed user interfaces for specialized applications, ensuring responsive design and user-friendly experiences.</li>
  <li>Implemented state management using Redux Toolkit and styled components with Chakra UI.</li>
  <li>Simplified content management for administrators and facilitated seamless integration with backend services.</li>
  <li>Developed sturdy web applications using React and TypeScript for managing complex user roles and administrative tasks.</li>
  <li>Ensured secure access control and efficient algorithm administration.</li>
  <li>Collaborated with cross-functional teams to ensure seamless integration and functionality.</li>
          "
          webDomain="ikubinfo.al"
        />
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        className="expAnimation"
        animateOnce
      >
        <Dropdown
          showDropdown={showDropdown2}
          title="Software Developer @ demogency - Part Time"
          startYear="March 2024"
          endYear="present"
          setShowDropdown={() => {
            setShowDropdown(false);
            setShowDropdown1(false);
            setShowDropdown3(false);
            setShowDropdown2(!showDropdown2);
          }}
          location="Prishtina, Kosovo"
          website=""
          stacks={stacksDemogency}
          logo={""}
          expDesc="
         <strong>React TypeScript & React Native Developer</strong>
          <li>Developed and maintained a resilient React TypeScript web app for streamlined candidate management, enhancing recruiter-candidate connections.</li>
  <li>Led UI design for a user-friendly, visually appealing interface, ensuring type safety and code quality with TypeScript.</li>
  <li>Integrated backend services for real-time data updates, ensuring smooth front-end and back-end interactions.</li>
  <li>Implemented state management using Context API, focusing on performance optimization and minimizing load times.</li>
  <li>Collaborated with designers, backend developers, and product managers for project success.</li>
  <li>Developed a complementary React Native mobile app to extend the system's functionality to mobile users.</li>
  <li>Ensured feature parity with the web app for seamless cross-device access, designing a responsive and intuitive mobile UI.</li>
  <li>Conducted thorough testing and debugging to ensure a stable, reliable mobile application.</li>
          "
          webDomain=""
        />
      </AnimationOnScroll>

      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        className="expAnimation"
        animateOnce
      >
        <Dropdown
          showDropdown={showDropdown3}
          title="Mentoring @ Render - Part Time"
          startYear="Jun 2023"
          endYear="Sep 2023"
          setShowDropdown={() => {
            setShowDropdown(false);
            setShowDropdown1(false);
            setShowDropdown2(false);
            setShowDropdown3(!showDropdown3);
          }}
          location="Prishtina, Kosovo"
          website=""
          stacks={stacksRender}
          logo={""}
          expDesc="
        <li>Guided junior developers, fostering problem-solving skills and supporting their growth.
Introduced new technologies, programming languages, and tools to expand their technical
expertise. Conducted code reviews, offering constructive feedback to elevate code quality and
reinforce coding standards</li>
          "
          webDomain=""
        />
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animatePreScroll={false}
        className="expAnimation"
        animateOnce
      >
        <Dropdown
          showDropdown={showDropdown1}
          title="Front-end Developer @ Black Bird Marketing"
          startYear="Jul 2021"
          endYear="Sep 2022"
          setShowDropdown={() => {
            setShowDropdown(false);
            setShowDropdown2(false);
            setShowDropdown3(false);
            setShowDropdown1(!showDropdown1);
          }}
          location="Prishtina, Kosovo"
          website="https://www.blackbird.marketing/"
          stacks={stacksBbm}
          logo={blackbird}
          expDesc="
          <li>
          I've successfully delivered React.js projects integrated with headless CMS solutions for
international clients in Ireland and the USA. These projects involved using headless CMS to
efficiently manage website content created with React.js, ensuring dynamic and user-friendly
web experiences</li><li>Additionally, I developed a Flutter/Dart application that facilitated connections between
influencers and businesses for marketing campaigns. This app streamlined the process of
businesses finding influencers, enhancing the effectiveness of marketing campaigns.</li>"
          webDomain="blackbird.marketing"
        />
      </AnimationOnScroll>
    </div>
  );
};

export default Experience;
