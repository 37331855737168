/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import "../Style/footer.scss";
import { ImSmile } from "react-icons/im";
import fb from "../Assets/fb.svg";
import ig from "../Assets/ig.svg";
import mail from "../Assets/mail.svg";
import linkedin from "../Assets/linkedin.svg";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const [thankyou, setThankyou] = useState(false);
  const [disable, setDisable] = useState(false);
  const form = useRef();
  const form2 = useRef();

  // send email function
  const sendEmail = (e) => {
    e.preventDefault();
    setDisable(true);
    emailjs
      .sendForm(
        "service_26t84ep",
        "template_vt3r1vb",
        form.current,
        "1iFnuQR492Hjh2Zlc"
      )
      .then(
        (result) => {
          setThankyou(true);
          setDisable(false);
        },
        (error) => {
          console.log(error.text);

          setDisable(false);
        }
      );
  };
  const sendEmail2 = (e) => {
    e.preventDefault();
    setDisable(true);
    emailjs
      .sendForm(
        "service_26t84ep",
        "template_vt3r1vb",
        form2.current,
        "1iFnuQR492Hjh2Zlc"
      )
      .then(
        (result) => {
          setThankyou(true);
          setDisable(false);
        },
        (error) => {
          setDisable(false);
        }
      );
  };
  return (
    <div className="footerMainWrapper" id="contact">
      <div className="footerInnerWrapper">
        <div className="footerInfo">
          <h1>Available for freelance opportunities</h1>
          <p>
            Have an exciting project you need help with? Fill the form or
            contact me via instant message!
          </p>
          <form ref={form2} onSubmit={sendEmail2}>
            <h1>Contact me</h1>
            <div className="inputGroup">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                name="from_name"
                type="text"
                placeholder="Name"
                required
                disabled={disable}
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="lastName">Lastname</label>
              <input
                id="lastName"
                name="from_Lname"
                type="text"
                placeholder="Lastname"
                required
                disabled={disable}
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="from_email"
                type="email"
                placeholder="Email"
                required
                disabled={disable}
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="from_mssg"
                placeholder="Your message..."
                required
                disabled={disable}
              />
            </div>
            <button
              type="submit"
              className="hoverable"
              // formTarget="dummyFrame"
              disabled={disable}
            >
              Send Message
            </button>
            {thankyou ? (
              <div className="thankyouMessageOverlay">
                <h1>
                  Thankyou! I will contact you shortly. <ImSmile />
                </h1>
              </div>
            ) : null}
          </form>
          <div className="emailLink">
            <a
              href="mailto:gashiorik11@gmail.com"
              className="footerEmail chonky-underline-violet hoverable"
            >
              {" "}
              <img src={mail} alt="social media" />
              gashiorik11@gmail.com
            </a>
          </div>
          <a
            href="https://www.linkedin.com/in/orik-gashi/"
            target="_blank"
            className="hoverable"
            rel="noreferrer"
          >
            <img src={linkedin} alt="social media" />
            LinkedIn
          </a>
          <a
            href="https://www.facebook.com/orik.gashi/"
            target="_blank"
            className="hoverable"
            rel="noreferrer"
          >
            <img src={fb} alt="social media" />
            Facebook
          </a>
          <a
            href="https://www.instagram.com/orikgashi/"
            target="_blank"
            className="hoverable"
            rel="noreferrer"
          >
            <img src={ig} alt="social media" />
            Instagram
          </a>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <h1>Contact me</h1>

          <div className="inputGroup">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="from_name"
              type="text"
              placeholder="Name"
              required
              disabled={disable}
            />
          </div>
          <div className="inputGroup">
            <label htmlFor="lastName">Lastname</label>
            <input
              id="lastName"
              name="from_Lname"
              type="text"
              placeholder="Lastname"
              required
              disabled={disable}
            />
          </div>
          <div className="inputGroup">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="from_email"
              type="email"
              placeholder="Email"
              required
              disabled={disable}
            />
          </div>
          <div className="inputGroup">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="from_mssg"
              placeholder="Your message..."
              required
              disabled={disable}
            />
          </div>
          <button
            type="submit"
            formTarget="dummyFrame"
            className="hoverable"
            onClick={() => {}}
            disabled={disable}
          >
            Send Message
          </button>
          <iframe style={{ display: "none" }} name="dummyFrame"></iframe>
          {thankyou ? (
            <div className="thankyouMessageOverlay">
              <h1>
                Thankyou! I will contact you shortly. <ImSmile />
              </h1>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default Footer;
