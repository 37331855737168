import mobile from "./Assets/mobile.png";
import software from "./Assets/software.png";

export const expertise = [
  {
    preTitle: "Software",
    title: "Developer",
    desc: "Experienced in both functional and OOP: Dart, JavaScript. Proficient in React,Angular Node.js, Express.js, and MongoDB.",
    img: software,
  },
  {
    preTitle: "Mobile",
    title: "Developer",
    desc: "Skilled in developing mobile applications using Flutter and React Native frameworks. Demonstrates expertise in crafting high-performance, user-friendly mobile solutions. Committed to leveraging these frameworks to deliver seamless and scalable applications.",
    img: mobile,
  },
];
